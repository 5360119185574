import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const section = css`
  position: relative;
`;

export const root = css`
  width: 100%;

  @media ${breakpoints.medium} {
    min-width: 180px;
  }
`;

export const input = css`
  width: 100%;
  height: 100%;
  min-height: 32px;
  border-radius: 100px;
  border: none;
  outline: none;
  ${font.size.tiny};
  padding: ${spacing(8)} ${spacing(36)} ${spacing(9)} ${spacing(12)};
  background: hsl(var(--color-grayscale-9));
  color: hsl(var(--color-grayscale-1));
  transition: border 0.2s, background 0.2s;
  border: 1px solid hsl(var(--color-grayscale-9));

  &:hover,
  &:active,
  &:focus {
    background: hsl(var(--color-grayscale-8));
    border: 1px solid hsl(var(--color-grayscale-5));
  }
`;

export const inputLight = css`
  ${input};
  background: transparent;
  color: hsl(var(--color-text-1));
  border: 1px solid hsl(var(--color-borderBlack-2));

  &:hover,
  &:active,
  &:focus {
    background: hsl(var(--color-grayscale-2));
    border: 1px solid hsl(var(--color-borderBlack-2));
  }
`;

export const icon = css`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const iconLight = css`
  ${icon};

  path {
    fill: hsl(var(--color-text-1));
  }
`;

export const closeIcon = css`
  ${icon};
  max-width: 16px;
  max-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.smallMax} {
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  &:hover {
    cursor: pointer;
  }
`;

export const closeIconLight = css`
  ${closeIcon};

  path {
    stroke: hsl(var(--color-text-1));
  }
  circle {
    stroke: hsl(var(--color-text-1));
  }
`;

export const fixedWrapper = css`
  border-radius: 100px;

  @media ${breakpoints.medium} {
    min-width: 180px;
  }
`;

export const wrapper = css`
  ${fixedWrapper};

  overflow: hidden;
`;
